<template>
  <div class="relate">
    <header class="page-header">
      <div class="container">
        <div class="page-header-flex-wrap no-margin-bottom">
          <img src="https://assets.website-files.com/5f0763bf575ff7f28b96ccb8/5f1c65e5f60f9435905caff2_Relate%20Avatar.svg" alt="" class="integration-icon"/>
          <div class="integration-title-flex">
            <h1 class="page-title section-header on-integration-page">Relate</h1>
            <h1 class="page-title section-header">Integration</h1>
          </div>
        </div>
      </div>
    </header>
    <div class="section medium">
      <div class="container">
        <div class="project-rich-text w-richtext">
          <h2>Seamless Integration</h2>
          <p>Future but just as terrestrial explorers have always led the way for settlers, this will also happen extraterrestrially. but not quite touching; however, when listening to the tape, many people do not hear the &#x27;a. if we survive, our time will be famous for two reasons: that at this dangerous moment of technological adolescence we managed to avoid self-destruction; and because this is the epoch in which we began our journey to the stars. it could have been in design, build, or test. it is in the long run essential to the growth of any new and high civilization that small groups of men can escape from their neighbors and from their government, to go and live as they please in the wilderness. it marks our entrance into a new era. temperature of the various stars as forever denied to us. the colors are stunning. there is not in sight any source of energy that would be a fair start toward that which would be necessary to get us beyond the gravitative control of the earth. they were dancing again when the first americans landed on the moon. this is houston, say again please. we copy you on the ground.Hella life pop-up this may seem wild, and visionary; all i maintain is that it is not unscientific. a spacecraft is a metaphor of national inspiration: majestic, technologically advanced, produced at dear cost and entrusted with precious cargo, rising above the constraints of the earth. al is on the surface.
          </p>
          <figure class="w-richtext-align-center w-richtext-figure-type-image">
            <div>
              <img src="https://assets.website-files.com/5f0763bf575ff7f28b96ccb8/5f0f124bc107c6e4c09f5d2e_pankaj-patel-1366018-unsplash(1).jpg" alt=""/>
            </div>
            <figcaption>This is a caption</figcaption>
          </figure>
          <p>billions and billions. but he can, lowes, and wants to go to there, and he will go. but if a man would be alone, let him look at the stars. huygens sketches of mars i, solitary person, but attentive, i am your eyes and i say to you: courage! isolationism is neither a practical policy on the national or cosmic scale. it is the very error of the moon; nasa owes it to the citizens from whom it asks support to be frank, honest, and informative... one does not have to undertake a space flight to come by this feeling. only realistic flight schedules should be proposed, schedules that have a reasonable chance of being met. our two greatest problems are gravity and paperwork. she&#x27;s decisive, she&#x27;s aggressive, she&#x27;s proven she&#x27;s capable with high-performance jets. srm clevis joint leakage study 1977 teetering here on the fulcrum of destiny stands our own bemused species. the difficulties of a trip to mars are formidable... the probability of success is difficult to estimate; but if we never search the chance of success is zero. the skies proclaim the work of his hands. this is something that we can&#x27;t do; we have the tiger by the tail. this race is not [about] political prestige or military power. well above the haze layer of the earth&#x27;s atmosphere were additional faint thin bands of blue, sharply etched against the dark sky.
          </p>
          <p>‍</p>
          <h2>This is a heading</h2>
          <p>And that leads, of course, to a strong suspicion that everybody else can do it if they want to. and then, a journey into tomorrow, a journey to another planet, a manned mission to mars. armstrong&#x27;s words. as far as i know, an alien spacecraft did not crash in roswell, new mexico, the fraction of the above that actually go on to develop life at some point. if they be inhabited, what a scope for misery and folly; if they be na inhabited, what a waste of space. in spite of the opinions of certain narrow-minded people who would shut up the human race upon this globe, we shall one day travel to the moon, the planets, and the stars with the same facility, rapidity and certainty as we now make the ocean voyage from liverpool to new york. is it not demonstrated that a true flying machine, self-raising, self-sustaining, self-propelling, is physically impossible? let othello subject desdemona to a lie-detector test; his jealousy will still blind him to the evidence. new stars offer to the mind a phenomenon more surprising, and less explicable, than almost any other in the science of astronomy. now approaching lunar sunrise. only when the cause is understood and a change to eliminate it has been made and verified, can we proceed with the flight program. our failure would lead to consequences unthinkable.
          </p>
          <blockquote>Harum sed eos ullam. Nulla aut quasi distinctio modi voluptatibus sit et. Doloremque sunt qui recusandae non corporis ea. Expedita vel adipisci soluta et minima recusandae nostrum minima placeat. Sequi debitis earum rerum est est rerum autem dignissimos.</blockquote>
          <p> since the beginning of time, mankind has considered it as an expression of its earthly weakness and inadequacy to be bound to the earth, to be unable to free itself from the mysterious shackles of gravity. the contemplation of celestial things will make a man both speak and think more sublimely and magnificently when he descends to human affairs. the mass gross absence of sound in space is more than just silence. the outstanding feature, however, is the possibility that the velocity-distance relation may represent the de sitter effect, and hence that numerical data may be introduced into discussions of the general curvature of space. there are no practical alternatives to air transportation. this item is not even worth mentioning other than wanting to make sure that you are not surprised by it in a question from a reporter... this planet is not terra firma. we will return to the moon no later than 2020 and extend human presence across the solar system and beyond. what the space program needs is more english majors.
          </p>
          <p>‍</p>
          <h2>This is a heading</h2>
          <p>Future but just as terrestrial explorers have always led the way for settlers, this will also happen extraterrestrially. but not quite touching; however, when listening to the tape, many people do not hear the &#x27;a. if we survive, our time will be famous for two reasons: that at this dangerous moment of technological adolescence we managed to avoid self-destruction; and because this is the epoch in which we began our journey to the stars. it could have been in design, build, or test. it is in the long run essential to the growth of any new and high civilization that small groups of men can escape from their neighbors and from their government, to go and live as they please in the wilderness. it marks our entrance into a new era. temperature of the various stars as forever denied to us. the colors are stunning. there is not in sight any source of energy that would be a fair start toward that which would be necessary to get us beyond the gravitative control of the earth. they were dancing again when the first americans landed on the moon. this is houston, say again please. we copy you on the ground.Hella life pop-up this may seem wild, and visionary; all i maintain is that it is not unscientific. a spacecraft is a metaphor of national inspiration: majestic, technologically advanced, produced at dear cost and entrusted with precious cargo, rising above the constraints of the earth. al is on the surface.
          </p>
          <p>‍</p>
        </div>
      </div>
    </div>
    <Relates/>
  </div>
</template>

<script>
import Relates from '@/sections/Relates';

export default {
  name:       'Relate',
  components: {Relates}
}
</script>

<style lang=scss>
@import '../assets/scss/base/variables';
</style>
